import React, { useEffect, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import CollectifAnnuaireItem from "./CollectifAnnuaireItem";
import Layout from "./Layout";
import Loader from "./Loader";
import useApiUrlTranslation from "./useApiUrlTranslation";

const CollectifAnnuaire = () => {

  const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

  let { cycle_lang_type } = useParams();

  const [cycle_lang_list, setCycleLangList] = useState(null);

  //const page_title = `${cycle_lang_type.split("_")[0]}s ${cycle_lang_type.split("_")[1]}s`;

  let page_title;
  let pageSubtitle;

  switch (cycle_lang_type) {

    case 'collectif_geographique':
      page_title = t("nav.collectifs_geographiques");
      pageSubtitle = `Bienvenue dans l'annuaire des hubs continentaux de myGlobalVillage.
      Vous souhaitez rejoindre la galaxie myglobalvillage et bénéficier d'une offre d'essaie Freemium de 3 mois. Sélectionnez-le ou les pays dans la liste ci-dessous sur lesquels vous êtes actifs et rejoignez les cercles d'intelligence collective de votre choix.`;

      break;
    case 'collectif_sectoriel':
      page_title = t("nav.collectifs_sectoriels");
      break;

    case 'collectif_technologique':
      page_title = t("nav.collectifs_technologiques");
      break;

    case 'super_collectif':
      page_title = t("nav.collectifs_super");
      break;

    default: page_title = cycle_lang_type
  }


  useEffect(() => {

    const fetchCycleLang = async () => {

      let req_suite;

      if (cycle_lang_type === "all") {
        req_suite = ` IN('collectif_geographique', 'collectif_technologique','collectif_sectoriel','super_collectif','collectif_partenaire') `;
      } else if (cycle_lang_type == "collectif_geographique") {
        req_suite = ` = 'collectif_geographique' AND cycle_lang_categorie IN('continent')`;

      } else {
        req_suite = ` = '${cycle_lang_type}'`;
      }

      const url = `${apiUrlTranslation}?action=getCycleLang&filter=cycle_lang_type ${req_suite} AND cycle_lang_state NOT IN('hidden') AND  `;

      await fetch(url)
        .then((res) => res.json())
        .then((res) => {
          setCycleLangList(
            res.filter(
              (cycle_lang) => cycle_lang.cycle_lang_type === cycle_lang_type
            )
          )
        })
    }

    fetchCycleLang();

  }, [
    page_title,
    cycle_lang_type,
    i18n.resolvedLanguage
  ])

  return (
    <Layout darkmode>
      <div className="max-w-7xl mx-auto">
        <h1 className="py-3 m-3 text-6xl font-extrabold animatedgradient">{`${page_title}`}</h1>

        <div className="lead my-3 text-gray-400">{pageSubtitle}</div>
        <div className="row border-bottom p-2">
          <div className="col-md-5 fw-bold"></div>
          <div className="col-md-5 fw-bold hidden">{t("global.coordinateurs")}</div>
          <div className="col-md-2"></div>
        </div>
        <p className="text-4xl py-3 hidden">Actifs</p>
        {cycle_lang_list ? (
          cycle_lang_list
            .filter(cycle_lang => cycle_lang.cycle_lang_state == 'active')
            .map((cycle_lang) => (
              <CollectifAnnuaireItem cycle_lang={cycle_lang} />
            ))
        ) : (
          <Loader />
        )}

        <p className="text-4xl py-3 mt-4">En cours de développement</p>
        {cycle_lang_list ? (
          cycle_lang_list
            .filter(cycle_lang => !['active', ''].includes(cycle_lang.cycle_lang_state))
            .map((cycle_lang) => (
              <CollectifAnnuaireItem cycle_lang={cycle_lang} />
            ))
        ) : (
          <Loader />
        )}
      </div>
    </Layout>
  )
}

//CollectifAnnuaireItem
//CollectifAnnuaireItemContact

export default withRouter(CollectifAnnuaire);
