import React, { useEffect, useContext, useState } from 'react'
import {
    useParams

} from "react-router-dom";
import Layout from './Layout'

import ContactsPhotoSlider from './ContactsPhotoSlider';
import VideoPlanning from './VideoPlanning';
import PageSection from './PageSection';
import EventTimer from './EventTimer';
import EventHeader from './EventHeader'

import EventContext from '../contexts/EventContext'
import VideoCover from './VideoCover';
import BilanChiffresCles from './BilanChiffresCles';
import moment from 'moment';
import PartenairesLogoSlider from './PartenairesLogoSlider';
import { useTranslation } from 'react-i18next';
import VideoSlider from './VideoSlider';

import Tarifs from './Tarifs';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import './EventLandingPage.css'
import Interstellar from './Interstellar';
import Carrousel from './Carrousel';
import PlanActionListSection from './PlanActionListSection';





const EventLandingPage = () => {

    const { t, i18n } = useTranslation();

    const { idEvent, id_cycle_lang } = useParams();

    const eventContext = useContext(EventContext);


    const [soutienStatutList, setSoutienStatutList] = useState(["copil", "charge_organisation", "charge_organisation", "stand_connecte",
        "coordinateur_collectif", "referent-lieu", "jury", "jura-a-distance", "soutien", "coordinateur_pitch", "offreur_de_solution",
        "animateur", "coordinateur_evenementiel", "coordinateur_jury", "coordinateur_techxploration",
        "territoire-connecta", "territoire-leader", "soutien", "contributeur", "coordinateur_geographique", "coordinateur_techxploration",
        "partenaire_demonstrateur", "coordinateur-de-la-da-la-gation", "coordinateur_evenementiel", "coordinateur_jury", "coordinateur_techxploration",
        "coordinateur_pavillon", "coordinateur_collectif_planaction", "coordinateur_collectif_partenaire", "coordinateur_evenementiel", "coordinateur_soiree",
        "coordinateur_collectif_linguistique", "offreur_de_solution", "animateur", "coordinateur_pitch", "intervenant", "partenaire_officiel"])

    const { currentEvent, getEventByIdEvent } = eventContext;

    const videoPlanningTitle = "Video lives ";

    const bg = currentEvent && currentEvent.mega_demonstrateur && currentEvent.mega_demonstrateur.color_2 !== '#000000' ? { "backgroundImage": `linear-gradient(90deg, ${currentEvent.mega_demonstrateur.color_2} 0%, ${currentEvent.mega_demonstrateur.color} 35%, ${currentEvent.mega_demonstrateur.color_2} 100%)`, color: "#fff" }
        : { background: "#f5f5f5" }
    useEffect(() => {
        getEventByIdEvent(idEvent);

        switch (idEvent) {

            case '1752': setSoutienStatutList([...soutienStatutList, 'participant']);
                break;
        }

    }, [idEvent])

    return (
        <>
            {currentEvent && <Layout className="pt-0 " Layout title="" displayNavEvent={true}>

                {
                /* currentEvent && moment(currentEvent.event_start).isAfter() && currentEvent.event_etat !== "bilan" && <EventHeader event={currentEvent} />
                */                }

                <PageSection
                    style={{
                        background: 'radial-gradient(20% 50% at 50% 50%,rgba(94, 6, 44, 0.67) 0,rgba(37,38,44,0) 100%)'
                    }}
                    className="background-gradient border-b-neutral-800 border-b"
                    darkmode>
                    <div className='container dark:text-white text-6xl lg:text-8xl   mx-auto font-extrabold py-3'>
                        <span className="animatedgradient py-3">{currentEvent.nom}</span></div>

                    <div className="flex items-center justify-center text-center dark:text-white text-xl lg:text-2xl mx-auto mt-3">

                        <ul className="flex lg:flex-row flex-col">
                            <li className="mr-10"> <i className="far fa-calendar-minus dark:text-white"> </i> {currentEvent.precision_date}  </li>
                            <li> <i className="fas fa-map-marker-alt dark:text-white"></i> {currentEvent.lieu.lieu_nom}  -  {currentEvent.lieu.lieu_ville}   {currentEvent.lieu.lieu_pays}</li>
                        </ul>
                    </div>

                    <div className="hidden  justify-center mx-auto flex flex-col lg:flex-row mt-5 pt-4 mb-5 space-y-4 lg:space-y-0 px-3 lg:px-0">
                        {!["2270", '3014', '3015'].includes(id_cycle_lang) &&
                            <Link to={currentEvent ? `/events/${currentEvent.id_event}/login` : ' / login'}
                                className='   text-lg items-center justify-center rounded-full border-pink-600 hover:border-pink-500 border-2    px-5 py-3   dark:text-white  lg:mx-2'>{t('global.connexion')}</Link>}
                        {currentEvent && currentEvent?.event_etat !== "bilan" && !["2270", "3013", "3014", "3015"].includes(id_cycle_lang) && <Link
                            style={{ textTransform: "capitalize." }}
                            className='shadow shadow-blue-500/40 registration-hero text-lg items-center justify-center rounded-full  bg-pink-600 hover:bg-pink-500 px-5 py-3 font- text-white ' to={`/events/${currentEvent?.id_event}/registration`}>{t('event_nav.inscription')}</Link>}

                    </div>
                </PageSection>



                {

                    idEvent == 1822 && <>
                        <PageSection
                            className="bg-white text-2xl "
                            title="Le rendez-vous incontournable des acteurs innovants de la logistique de la Supply Chain à Cambraisur le site de E-Valley.">

                            <div className=" mx-auto">
                                <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/240129183604_capture-d-e-cran-2024-01-29-a-18.24.57.png" />

                                <p className=" max-w-7xl mx-auto py-3">
                                    Explorez les dernières tendances et innovations de la Supply Chain lors de conférences et de tables rondes inspirantes, de sessions interactives, de présentations captivantes et de démonstrations en direct.
                                </p>
                            </div>
                        </PageSection>
                    </>
                }


                <Carrousel title="Au programme" destinationList={"event_landing_page"} />

                {currentEvent && moment(currentEvent.event_start).isAfter() && currentEvent.event_etat !== "bilan" && <PageSection

                    darkmode
                    title={t("sections.timer.title")}
                    style_={bg}>

                    <EventTimer
                        big date={currentEvent.event_start.substring(0, 10)}
                        heure_debut={currentEvent.event_start.substring(11, 16)}>
                    </EventTimer>

                </PageSection>}




                {
                    idEvent == 1822 && <PageSection
                        className="bg-white"
                        title="Tables rondes et conférences">

                        <div className="flex flex-col lg:flex-row  mx-auto text-left max-h-96 lg:text-2xl max-w-7xl " >
                            <div className="overflow-hidden">
                                <img src="https://www.mlg-consulting.com/manager_cc/docs/archives/240129183604_capture-d-e-cran-2024-01-29-a-18.26.59.png" className="w-100 " />
                            </div>
                            <div className="px-3">
                                Plongez au cœur des enjeux majeurs lors de tables rondes animées par des personnalités politiques et industrielles :
                                <ul className="list-disc text-left ml-10">
                                    <li>La logistique du futur</li>
                                    <li>Les politiques RSE</li>
                                </ul>

                                <p className="my-4"> Participez aux conférences où des acteurs majeurs du secteur partageront leur vision concernant : </p>

                                <ul className='list-disc text-left ml-10'>
                                    <li> La Smart Supply</li>
                                    <li>Le dernier kilomètre</li>
                                    <li>Le transport fluvial</li>
                                    <li> La décarbonation</li>
                                </ul>
                            </div>
                        </div>
                    </PageSection>
                }


                {currentEvent.id_event === "17690" && <PageSection darkmode className="text-white">
                    <h1>
                        <span className='font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>
                            MyGlobalVillage Cannes Next - Marché du film - Festival de Cannes</span>
                        <span className='mb-5 ml-3 font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-orange-600 to-yellow-400'>EN LIVE</span>
                    </h1>
                    {/** https://vimeo.com/event/3409547/embed */}

                    <div className='lg:px-52'>
                        <iframe
                            className="b-0"
                            width="100%"
                            height="500"
                            src="https://vimeo.com/event/3423101/embed"
                            title="MyGlobalVillage Cannes Next - Marché du film - Festival de Cannes"
                            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>

                </PageSection>
                }

                {currentEvent.id_event == "17270" && <Tarifs />}

                {currentEvent.id_event === "17790" &&

                    <>
                        <PageSection className="bg-black text-white">
                            <h1><span className='font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>VILLAGE NUMERIQUE ULTRA MARIN</span>
                                <span className='mb-5 ml-3 font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-orange-600 to-yellow-400'>EN LIVE</span></h1>
                            <div className='px-52'>
                                <iframe
                                    className="b-0"
                                    width="100%"
                                    height="500"
                                    src="https://www.youtube.com/embed/RMtJ91a51KA"
                                    title="VILLAGE NUMERIQUE ULTRA MARIN"
                                    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </PageSection>

                        <PageSection title="Le mot du président">

                            <div className="flex flex-row px-5 text-xl font-medium leading-9 text-gray-900">
                                <div className="basis-1/6 mr-5">
                                    <img src="//www.mlg-consulting.com/manager_cc/docs/archives/221124132756_portrait-hm.jpg" alt="" className="rounded-full" />
                                    <img className="mt-5" src="//www.mlg-consulting.com/manager_cc/docs/archives/221124132756_logo-fedom-2022.png" alt="" />
                                </div>
                                <div className="basis-3/4 text-left ml-4">
                                    <p> La vocation  principale de la FEDOM consiste à promouvoir la dimension ultramarine de la France et à soutenir le développement économique de ces territoires en veillant aux intérêts de leurs entreprises, en particulier dans les évolutions législatives et réglementaires qui les concernent et dans le développement d’outils au service de leur performance et de leur rayonnement local, régional, national et international.</p>
                                    <p className="py-3"> Le village numérique ultramarin, hameau du Village global, s’inscrit dans cette mission, lui qui a pour vocation de mettre en lumière les entreprises innovantes de l’Outre-mer, dont l’agilité issue de leurs spécificités mérite d’être rendue visible à la plus large échelle, auprès de leurs clients, investisseurs, partenaires potentiels où qu’ils soient. </p>
                                    <p>Nos territoires, pour la plupart insulaires sont éloignés et souvent isolés. Le numérique abolit les frontières ; il est donc important que nous participions collectivement à ce mouvement de valorisation des compétences qui y sont déployées dans le domaine du digital.</p>
                                    <p className="pt-3 font-bold"> Hervé MARITON</p>
                                    <p className=" text-indigo-600"> Président</p>
                                </div>
                            </div>
                        </PageSection>
                    </>}

                {currentEvent.id_event == "17890" && <Interstellar />}


                {currentEvent.event_etat === "bilan_" &&
                    <VideoCover>
                        <BilanChiffresCles />
                    </VideoCover>}
                {
                    //&& moment(currentEvent.event_start).isAfter() && 
                }
                {currentEvent && currentEvent.id_event == 17720 && <PageSection
                    className="bg-neutral-950 text-white" title={currentEvent.event_etat === 'bilan' ? `Retrouvez les vidéos de l'édition ${currentEvent.event_an}` : videoPlanningTitle} >
                    <VideoPlanning />
                </PageSection>}

                {currentEvent.event_etat === "bilan_" &&
                    <PageSection title="Replay">
                        <VideoSlider />
                    </PageSection>
                }

                {
                    // t('sections.soutiens.title')
                    !["1715"].includes(idEvent) && <PageSection title={currentEvent.id_event === 1713 ? "Ils ont soutenu l'événement, ils étaient présents" : " ils coordonnent l'événement"}>
                        <ContactsPhotoSlider
                            displayMode={idEvent == 1822 ? "fixed" : ""}
                            statutList={soutienStatutList} />
                    </PageSection>
                }

                {/* {
                    !["1715"].includes(idEvent) && <PageSection title={currentEvent.id_event === 1713 ? "Ils ont soutenu l'événement, ils étaient présents" : t('sections.soutiens.title')}>
                        <ContactsPhotoSlider
                            displayMode="fixed"
                            statutList={soutienStatutList} />
                    </PageSection>

                } */}

                { /*<Section title="Hôtels et agences accrédités"></Section>*/}

                { /* <PageSection title="Les partenaires Officiels">
                    <PartenairesLogoSlider
                        displayContactsCycleLang
                        params={` AND cf.statut IN('partenaire_officiel') AND id_event=${idEvent} GROUP BY c.id_contact`} />
                </PageSection>
                */}

                {["1752"].includes(idEvent) && <PageSection title="Les exposants">
                    <PartenairesLogoSlider
                        params={` AND cf.statut IN('offreur_de_solution') AND id_event=${idEvent} GROUP BY c.id_contact`} />
                </PageSection>
                }

                {/* 
                {!["1715", "1752", '1838'].includes(idEvent) &&

                    <PageSection darkmode title={t('sections.partenaires.title')}>
                        <PartenairesLogoSlider

                            displayContactsCycleLang
                            params={` AND cf.statut IN('partenaire_national', 'partenaire_demonstrateur', 'coordinateur_pitch', 'coordinateur-de-la-da-la-gation', 'partenaire_officiel', 217, 250, 202, 'soutien') AND id_event=${idEvent} GROUP BY c.id_contact`} />
                    </PageSection>

                } */}

                { /*<Section title="Pour en savoir plus">Pour en savoir plus</Section>*/}



            </Layout >}
        </>
    )
}

export default EventLandingPage