import React, { useState } from 'react'
import PageSection from './PageSection'
import useApiUrlTranslation from './useApiUrlTranslation'

const ChiffreCle = ({ chiffreCle, number }) => {

  return <div className="p-3 border-2 rounded-2xl dark:border-neutral-900 bg-neutral-800/10">
    <div className="flex">
      <div className="basis-1/4">
        <img className="w-100" src={`https://www.mlg-consulting.com/manager_cc/docs/archives/${chiffreCle.img ?? '230911122414_participant-pentagon.svg'}`} />
      </div>

      <div className="counter text-left ps-3 basis-3/4">
        <div className="counter_icon"></div>
        <p id={`number${number}`} className='number text-purple-600 text-6xl font-black'>0</p>
        <h5 className="text-pink-500 text-xl"><i className={`${chiffreCle.icon} hidden`}></i> {chiffreCle.title}</h5>
        <span className=" dark:text-gray-200 text-xl">{chiffreCle.subtitle}</span>
      </div>
    </div>
  </div>
}

let animationState = ""

const ChiffresCles = () => {

  const { t } = useApiUrlTranslation();

  const chiffres = [
    { number: 1, title: t("sections.chiffres.items.alliance.title"), subtitle: t("sections.chiffres.items.alliance.subtitle"), icon: "fas fa-map-marker-alt", img: "230911122414_global-alliance-pentagon.svg" },
    { number: 19, title: t("sections.chiffres.items.pays.title"), subtitle: t("sections.chiffres.items.pays.subtitle"), icon: "fas fa-rocket ", img: "230911122414_countries-pentagon.svg" },
    { number: 46, title: t("sections.chiffres.items.collectifs.title"), subtitle: t("sections.chiffres.items.collectifs.subtitle"), icon: "fas fa-map-signs ", img: "230911122414_sector-pentagon.svg" },
    { number: 85, title: t("sections.chiffres.items.territoires.title"), subtitle: t("sections.chiffres.items.territoires.subtitle"), icon: "fas fa-map-signs ", img: "230911122414_territories-pentagon.svg" },
    { number: 4000, title: t("sections.chiffres.items.startups.title"), subtitle: t("sections.chiffres.items.startups.subtitle"), icon: "fas fa-user-friends", img: "230911122414_startup-pentagon.svg" },
    { number: 350000, title: t("sections.chiffres.items.participants.title"), subtitle: t("sections.chiffres.items.participants.subtitle"), icon: "fas fa-bullhorn ", img: "230911122414_participant-pentagon.svg" }
  ]

  const [keyNumberPosition, setKeyNumberPosition] = useState(null)

  const mobilecheck = () => {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  //fonction pour animer les nombres
  const animateNumber = (callback, from, to, duration) => {
    let start = null,
      animate = timestamp => {
        start = start || timestamp;
        let progress = Math.min((timestamp - start) / duration, 1);
        callback(progress * (to - from) + from);
        if (progress < 1) {
          window.requestAnimationFrame(animate);
        }
      };
    window.requestAnimationFrame(animate);
  }

  //Permet de repérer lorsque le bas de la div apparaît à l'écran
  document.addEventListener("scroll", () => {
    const height = window.innerHeight;
    mobilecheck() ? setKeyNumberPosition((document.getElementById("chiffreClef")?.getBoundingClientRect().top) - height) : setKeyNumberPosition((document.getElementById("chiffreClef")?.getBoundingClientRect().bottom) - height)
  })

  if (keyNumberPosition < 0 && animationState !== "played") {
    for (let i = 0; i < chiffres.length; i++) {
      animateNumber(value => {
        document.querySelector(`#number${i}`).textContent = Math.floor(value);
      }, 0, chiffres[i].number, 3000);
    }
    animationState = "played"
  }

  return (

    <PageSection title={t("sections.chiffres.title")} darkmode>
      <div className="container" id="chiffreClef">
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-3 mx-auto max-w-7xl">
          {chiffres.map((chiffre, index) => <ChiffreCle key={index} chiffreCle={chiffre} number={index} />)}
        </div>
      </div>
    </PageSection>
  )
}

export default ChiffresCles
