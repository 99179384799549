import React, { useEffect, useState } from 'react'
import RegistrationFormSection from './RegistrationFormSection'
import EventCycleLangPacks from './EventCycleLangPacks'
import { use } from 'i18next'
import useApiUrlTranslation from './useApiUrlTranslation'

const RegistrationFormShoppingCartSection = ({ contact, checkContactisTrue, handleLoginPaiement, id_event_cycle_lang }) => {

    const [eventCycleLang, setEventCycleLang] = useState(false);
    const [cycleLang, setCycleLang] = useState(false);
    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const getEventCycleLang = async (id_event_cycle_lang) => {
        await fetch(`${apiUrlTranslation}?action=getEventCycleLangList&params= WHERE id_event_cycle_lang = ${id_event_cycle_lang} `)
            .then(res => res.json())
            .then(res => {

                const ecl = res[0];
                setEventCycleLang(ecl)
                setCycleLang(ecl.cycle_lang)
            })
    }

    useEffect(() => {
        getEventCycleLang(id_event_cycle_lang)
    }, [i18n.resolvedLanguage])


    if (!eventCycleLang && !cycleLang) return null

    return (

        <EventCycleLangPacks
            checkContactisTrue={checkContactisTrue}
            handleLoginPaiement={handleLoginPaiement}
            eventCycleLang={eventCycleLang}
            cycleLang={cycleLang}
            contact={contact} />
    )
}

export default RegistrationFormShoppingCartSection