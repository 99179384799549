import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";


export const RegistrationFormSubtitleSection = ({ children }) => {
    return (
        <div className="bg-indigo-700 text-white p-3 my-2 rounded">
            <p className="font-bold flex"><ChevronDoubleRightIcon className="w-8"></ChevronDoubleRightIcon> {children}</p>
        </div>
    );
}


export const RegistrationFormSupertitleSection = ({ children }) => {
    return (
        <div className="bg-black text-white p-3 my-2 rounded uppercase">
            <p className="font-black flex text-2xl">{children}</p>
        </div>
    );
}

