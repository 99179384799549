import React, { useEffect, useState } from 'react'
import Layout from './Layout'
import useApiUrlTranslation from './useApiUrlTranslation';
import { useParams } from "react-router-dom";
import useGetCarrousel from '../customHooks/useGetCarrousel';
import HTMLReactParser from 'html-react-parser';

const Highligths = () => {

    const { idEvent } = useParams();

    const { t, i18n, } = useApiUrlTranslation();

    const { destinationList } = useParams();

    const [carrouselList] = useGetCarrousel({ idEvent, destinationList });

    const [pageTitle, setPageTitle] = useState(null);

    useEffect(() => {

        switch (destinationList) {
            case 'bilan':
                setPageTitle("Bilan : Les chiffres clés");
                break;

            case 'event-target':
                setPageTitle("Les cibles de l'événement");
                break; // Ajout d'un break ici pour éviter la chute dans le default

            default:
                setPageTitle("Les temps forts de l'événement");
        }

    }, [i18n.resolvedLanguage])


    return (
        <Layout title={pageTitle}>

            <div className='bg-white px-10'>

                {carrouselList && carrouselList.map((carrousel, index) =>
                    <div key={index} className="px-5 mx-auto flex   bg-white  shadow  py-3 my-2 rounded-lg">

                        <div className='basis-2/6'>
                            <img src={carrousel.files.medium} alt="" className='rounded-lg' />
                        </div>
                        <div className="basis-4/6 bg-white px-5 rounded-lg ">
                            <div className="text-2xl font-bold mb-4">{carrousel.titre}</div>
                            <p>{HTMLReactParser(carrousel.descriptif ?? "")}</p>

                        </div>
                    </div>
                )}</div>

        </Layout>
    )
}

export default Highligths