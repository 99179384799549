import React, { useEffect, useContext } from 'react'
import ChiffresCles from './ChiffresCles'
import Layout from './Layout'
import VideoCover from './VideoCover'
import PageSection from './PageSection'
import VideoPlanning from './VideoPlanning'
import ContactsPhotoSlider from './ContactsPhotoSlider'
import EventContext from '../contexts/EventContext';
import SocialMedias from './SocialMedias'
import PartenairesLogoSlider from './PartenairesLogoSlider'
import NextEvent from './NextEvent'
import PressArticle from './PressArticle'
import Carousel from './Carousel'
import { useTranslation } from 'react-i18next'
import Hero from './Hero'
import Interstellar from './Interstellar'
import ProgrammItemHilight from './ProgrammItemHilight'
import PlanActionListSection from './PlanActionListSection'

const Home = () => {

    const { t, i18n } = useTranslation();

    const eventContext = useContext(EventContext);

    const { getEventByIdEvent } = eventContext;

    useEffect(() => {
        getEventByIdEvent(1656);
        /* getEvents("  id_event IN (1656,1743) AND ");*/

    }, [i18n.language]);

    return (
        <Layout>
            {/* 
            <PageSection title="Video lives">
                <VideoPlanning displayReplay={true} />
            </PageSection>
            */}


            { /* <PageSection className="bg-black text-white">
                <h1><span className='font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>myGlobalVillage - Lancement SIIVim 2023</span>
                    <span className='mb-5 ml-3 font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-orange-600 to-yellow-400'>EN LIVE</span></h1>
                <div className='lg:px-52'>
                    <iframe
                        className="b-0"
                        width="100%"
                        height="500"
                        src="https://www.youtube.com/embed/w0C1kP3yBmk"
                        title="myGlobalVillage - CES Las Vegas 2023"
                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>

            </PageSection> */
            }

            {/* <Hero /> */}
            <VideoCover hide>
                { /* currentEvent && <EventStar event={currentEvent} /> */}

                { /* events.filter(event => ['1656', '1743'].includes(event.id_event)).map(event => <EventStar event={event} />) */}
                <div className='max-w-6xl mx-auto w-100 text-white pt-28 lg:pt-28 '>

                    <div className=" text-5xl lg:text-8xl    font-black">

                        Bienvenue sur <span className="animatedgradient font-black"> myglobalVillage</span></div>

                    <div className='text-2xl lg:text-3xl  mt-5'> Cercles d'Intelligence collective pour détecter et accélérer les solutions les plus innovantes partout sur la planète
                    </div>
                </div>
            </VideoCover>

            <PageSection title={t("sections.whatIs.title")} className="mt-5">
                <p className='dark:text-white text-2xl mx-auto pb-5 max-w-6xl '>
                    {t("sections.whatIs.body")}
                </p>
            </PageSection>

            <PlanActionListSection />

            {/* <PageSection darkmode>
                <Carousel />
            </PageSection> */}


            <ProgrammItemHilight />


            {
                /* <PageSection title="Les prochains Lives">
                    <VideoPlanning />
                    </PageSection> 
                    */
            }



            <ChiffresCles />
            {/* 
            <PageSection title={t("sections.partenaires_media.title")} darkmode>
                <div className="container"><p></p>
                    <PartenairesLogoSlider params={` AND id_event=1635 AND cf.statut IN('partenaire_media')GROUP BY c.id_contact  LIMIT 20`} />
                </div>
            </PageSection> */}

            {/* <PageSection title={t("sections.soutiens.title_past")} darkmode>
                <div className="container">
                    <p className='dark:text-white text-lg mx-auto pb-5 max-w-6xl '> {t("sections.soutiens.subtitle")}</p>

                    <ContactsPhotoSlider
                        statutList={["intervenant", "soutien"]}
                    /> </div>
            </PageSection> */}

            <PageSection title={t('nav.next_events')} darkmode>
                <NextEvent />
            </PageSection>
            {/* 
            <PageSection title={t("sections.news.title")} className="bg-light">
                <PressArticle />
            </PageSection>

            <PageSection title={t("sections.social.title")}>
                <SocialMedias />
            </PageSection>

     
            <PageSection title="Les partenaires et solutions ">
                <div className="container">
                    <LogoSlider params={` AND id_event=1635 GROUP BY c.id_contact LIMIT 20`} />
                </div>
            </PageSection>
            */}

            {/*   <PageSection title="Les prochains événements">
                <div className="container">
                    <p>
                        Hors période Covid, le village francophone vous accueille dans ses studios connectés partout dans le monde en tenue conjointe des événements les plus prestigieux en matière d'innovation.
                        Venez réseauter sur place, doper la visibilité de votre entreprise ou organisation et participer aux temps forts, aux sessions de pitch et aux parcours de visite thématiques des salons. Découvrez le calendrier détaillé des prochains événements
                    </p>
                </div>
            </PageSection>
            */}


        </Layout>
    )
}

export default Home