import parse from 'html-react-parser';
import React, { useState, useEffect, useContext, useRef } from 'react';
import Loader from './Loader';
import { API_URL } from '../config';
import EventContext from '../contexts/EventContext';
import Layout from './Layout';
import { removeDuplicateObjectFromArray, sortByAttribute } from '../utils';
import useApiUrlTranslation from './useApiUrlTranslation';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { ClipboardDocumentCheckIcon, PaperAirplaneIcon, ClipboardIcon, LinkIcon } from '@heroicons/react/24/outline';
import { use } from 'i18next';
import Onboarding from './Onboarding';
import { set } from 'react-hook-form';
import Input from './Ui/Input';
import PhoneNumberInput from './PhoneNumberInput';
import PhoneInput from 'react-phone-input-2';
import { stringify } from 'uuid';
import { CheckCircleIcon, PencilSquareIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';

const FormulaireHandler = ({ partenaire, handleSendForm, sendButtonRef, cycleLangEventLigneList }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [formulaireList, setFormulaireList] = useState(null);

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const getFormulaireList = async () => {

        await fetch(`${apiUrlTranslation}?action=getFormulaireList&params=WHERE id_formulaire IN (28, 47)`)
            .then(res => res.json())
            .then(res => setFormulaireList(res))
    }

    useEffect(() => {

        getFormulaireList();

    }, [])


    return <div className="rounded border mt-3 ">


        <table className=" w-full">
            <thead className="bg-pink-50  divide-y">
                <tr className="divide-x text-sm">
                    <th className="px-2 py-2">Form</th>
                    <th className="px-2">Show</th>
                    <th className="px-2">Send</th>
                </tr>
            </thead>

            <tbody className="divide-y">
                {formulaireList && formulaireList.map(formulaire =>
                    <tr className="divide-x bg-white">
                        <td className="text-xs px-2">{formulaire.name_short}</td>
                        <td className=" px-2">
                            <div className=" flex items-center justify-center">
                                <a
                                    className="cursor-pointer flex bg-pink-200 rounded-full px-2 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center"
                                    target="_blank" href={`/events/${currentEvent.id_event}/signup/${formulaire.route}/c/${partenaire.contact.id_contact}/?form-name=${formulaire.route}&formisvisible=1&id_formulaire=${formulaire.id_formulaire}&lng=en&id_cycle_lang_preselection_list=${cycleLangEventLigneList && cycleLangEventLigneList.map(cycleLangEventLigne => cycleLangEventLigne.id_cycle_lang.id_cycle_lang).join(',')}`}  > <LinkIcon className='w-4 ' /></a>
                            </div>
                        </td>
                        <td className=" p-2">
                            <div className=" flex items-center justify-center">

                                <a
                                    ref={sendButtonRef}
                                    idFormulaire={formulaire.id_formulaire}
                                    onClick={handleSendForm}
                                    className="cursor-pointer flex bg-pink-200 rounded-full px-2 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center">
                                    <PaperAirplaneIcon className='w-4 ' />
                                </a>
                            </div>
                        </td>
                    </tr>

                )}
            </tbody>
        </table>
    </div>
}

const EditContactDialog = ({ contact, setActiveContact, contactDialogIsOpen, setContactDialogIsOpen, contactConferencierList, setContactConferencierList }) => {

    const [message, setMessage] = useState(null);

    const { t } = useApiUrlTranslation();

    if (!contact) return null;

    const handleUpdateContactButton = async () => {

        const data = {
            id_contact: contact.id_contact,
            nom: contact.nom,
            prenom: contact.prenom,
            societe: contact.societe,
            port: contact.port,
            historique: contact.historique
        };

        await fetch(`${API_URL}?action=updateContact`, {

            method: 'POST',
            body: JSON.stringify({ formData: data })
        })
            .then(response => response.json())
            .then(data => setMessage('Contact updated successfully'))
    }

    const handleChange = (e) => {

        const newActiveContact = { ...contact, [e.target.name]: e.target.value };

        setContactConferencierList(contactConferencierList.map(item => item.contact.id_contact == contact.id_contact ? { ...item, contact: newActiveContact } : item));

        setActiveContact(newActiveContact);

        console.log(e.target.value);
    }

    return <dialog
        className="fixed inset-0   bg-black bg-opacity-50 h-screen rounded-lg shadow-lg p-6 z-50 "
        open={contactDialogIsOpen}>

        <div className="w-screen mx-auto">

            <div className="bg-blue-50 p-5 rounded-2xl max-w-4xl mx-auto">

                {message && <div className="bg-green-100 text-green-800 p-3 rounded-lg my-3">{message}</div>}
                <div className="flex justify-end items-center">
                    <button
                        className="bg-blue-50 hover:bg-neutral-100 text-black  border-none shadow-none p-2 rounded-full w-10 h-10"
                        onClick={() => setContactDialogIsOpen(false)}>X </button>
                </div>
                <div className="flex space-x-3 items-center">
                    <img src={contact.photos.small} className="rounded-full w-24 h-24 object-cover" alt="" />
                    <p className="text-2xl font-bold ">{contact.prenom} {contact.nom}</p>
                </div>
                <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">



                    <Input
                        name='nom'
                        handleChange={handleChange}
                        label={t('form.sections.contact_section.nom')} value={contact.nom} />

                    <Input
                        name='prenom'
                        handleChange={handleChange}
                        label={t('form.sections.contact_section.prenom')} value={contact.prenom} />

                    <Input
                        name='societe'
                        handleChange={handleChange}
                        label={t('form.sections.organisme_section.societe')} value={contact.societe} />

                    <Input
                        name='port'
                        handleChange={handleChange}
                        label="Mobile" value={contact.port} />

                    <Input
                        name="historique"
                        handleChange={handleChange}
                        inputType="textarea"
                        label="Comments" value={contact.historique} />

                    <div className="mt-5"><button onClick={() => handleUpdateContactButton()}>Save</button></div>

                </div>

            </div>
        </div>
    </dialog>

}

const ContactCycleLang = ({ title, contacyCycleLangList, cycleLangTypeList = [] }) => {


    if (
        !contacyCycleLangList || contacyCycleLangList
            .filter(item => cycleLangTypeList.includes(item.cycle_lang.cycle_lang_type)).length === 0
    ) return null;
    return <div>
        <p className='font-bold'>{title}</p>
        <div className='flex space-x-5 flex-wrap  '>


            {contacyCycleLangList &&

                //filter cycleLangTypeList

                contacyCycleLangList
                    .filter(item => cycleLangTypeList.includes(item.cycle_lang.cycle_lang_type))
                    .map(item => <div className="inline-flex space-x-1 items-center rounded-full my-1 bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">

                        <span className="text-xs"> {item.cycle_lang.nom_cycle_lang}</span>
                        <img src={item.cycle_lang.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} alt="" class="false  w-6 lg:w-6 rounded" />
                    </div>)}
        </div>
    </div>
}

const CandidatHorsJuryDemo = ({ index, partenaire, prestaList, showEditContactDialog, cycleLangEventLigneList }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [emailSent, setEmailSent] = useState(false);

    const sendButtonRef = useRef(null);

    const { i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [contacyCycleLangList, setContacyCycleLangList] = useState(null);

    const contactCycleLangData = [
        { title: "collectif_geographique", cycleLangTypeList: ["collectif_geographique"] },
        { title: "collectif_programme", cycleLangTypeList: ["collectif_programme"] },
        { title: "collectif_technologique", cycleLangTypeList: ["collectif_technologique"] },
        { title: "collectif_sectoriel", cycleLangTypeList: ["collectif_sectoriel"] },
        { title: "collectif_planaction", cycleLangTypeList: ["collectif_planaction"] },
        { title: "collectif_cercle", cycleLangTypeList: ["collectif_cercle"] },
        { title: "collectif_partenaire", cycleLangTypeList: ["collectif_partenaire"] }
    ];

    const fetchContactCycleLang = async ({ id_contact }) => {

        await fetch(`${apiUrlTranslation}?action=fetchContactCycleLang&filter=   c.id_contact=${id_contact} AND `)
            .then(res => res.json())
            .then(res => setContacyCycleLangList(res))
    }

    const sendEmailNotification = async ({ body, dest, email, mobile, objet }) => {

        // $dest = $data['dest'];
        // $objet = utf8_decode($data['objet']);
        // $body = $data['body'];

        await fetch(`${API_URL}?action=sendEmailNotification&debug=y`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({

                dest_: 'daviddaville@gmail.com',
                dest: dest,
                email: email,
                mobile, mobile,
                objet: objet,
                body: body
            }
            )
        })
    }

    const handleSendForm = async (e) => {

        //change button text    
        // sendButtonRef.current.innerHTML = "Email sending...";

        const button = e.currentTarget;
        e.preventDefault();

        button.innerHTML = "Email sending...";

        await fetch(`${API_URL}?action=sendFormUrlToPitchingSessionCandidate&idc=${partenaire.contact.id_contact}&ie=${currentEvent.id_event}&id_formulaire=${button.getAttribute('idFormulaire')}`)
            .then(response => response.json())
            .then(data => {
                setEmailSent(true);
                console.log(data);
                button.innerHTML = "email sent";
                button.classList.remove("bg-pink-200");
                button.classList.add("bg-green-200");
                button.classList.add("text-green-700");
                button.classList.remove("cursor-pointer");
                button.classList.remove("text-pink-700");
                button.removeAttribute("href");
            })
    }

    useEffect(() => {
        fetchContactCycleLang({ id_contact: partenaire.contact.id_contact });
    }, [i18n.resolvedLanguage])

    return <div
        className='bg-white flex flex-col lg:flex-row space-x-5 rounded-2xl'>


        <div className="flex items-center justify-center">
            <div className='rounded-full text-gray-400 p-2 items-center flex justify-center'>#{index + 1}</div>
        </div>
        <div className="flex  basis-2/12  space-x-3">
            <div className="flex flex-col   justify-between items-start border p-2 my-2 rounded">

                {partenaire.contact.logo != 'no_picture.jpg' ? <img src={partenaire.contact.logos.large} className='w-10' alt="" /> : <div className="bg-neutral-100 text-gray-200 p-2 rounded text-center text-xs">{partenaire.contact.societe}</div>}
                <div className='text-sm font-bold uppercase'>{partenaire.contact.societe}</div>
                <p className='text-xs text-gray-500 '>{partenaire.contact.mail}</p>
                <p className='text-xs text-gray-500'>{partenaire.contact.port}</p>
                <div className="flex space-x-1">
                    <div className="text-xs">{partenaire.contact.pays}</div>
                    <img src={partenaire.contact.flag} className="w-4 h-4" />
                </div>
            </div>
            <div className="  flex flex-col justify-between items-center py-1 border p-2 my-2 rounded">
                <img className="w-10 h-10 object-cover rounded-full"
                    src={partenaire.contact.photos.small} alt="" />
                <div className='text-xs'>{partenaire.contact.prenom} {partenaire.contact.nom}
                </div>
            </div>
        </div>

        <div className=" basis-9/12">

            {prestaList && prestaList.map(presta => {

                let videoUrl = presta.video_url ?? presta.video_hosted;

                if (presta.video_url) {
                    videoUrl = presta.video_url;
                } else if (presta.video_hosted) {
                    videoUrl = `https://www.mlg-consulting.com/manager_cc/docs/archives/${presta.video_hosted}`;
                } else if (partenaire.contact.video_presentation != "") {
                    videoUrl = partenaire.contact.video_presentation;
                } else if (partenaire.contact.organisme_video_url != "") {
                    videoUrl = partenaire.contact.organisme_video_url;
                } else if (partenaire.contact.organisme_video_hosted != "") {
                    videoUrl = `https://www.mlg-consulting.com/manager_cc/docs/archives/${partenaire.contact.organisme_video_hosted}`;
                }

                return <div>
                    <div>
                        <div className="py-3 font-bold">
                            {presta.presta_nom}
                        </div>
                    </div>
                    <div className='text-gray-500 text-xs'>

                        {partenaire.contact.video_presentation_url}

                        {
                            //remove html tags
                            parse(presta.presta_accroche.substring(0, 200)
                                .replace(/(<([^>]+)>)/gi, "") ?? " "
                            )}...</div>

                    {videoUrl ? <a target="_blank" className='text-green-600 hover:text-green-600 py-2 flex items-center  text-xs' href={videoUrl}> <CheckCircleIcon className="w-6" /> Video support : {videoUrl}</a> : <div className='text-red-600 pt-2 flex items-center  text-xs'><XCircleIcon className="w-6" /> No video support</div>}


                    {
                        //presta.presta_visuel ne contient pas  'no_picture_400' uiliser  la fonction includes

                        !presta.presta_visuel.includes('no_picture_400')
                            ? <div className='- flex items-center text-green-600 text-xs'> <CheckCircleIcon className="w-6" /> Illustration visual : <img src={presta.presta_visuel} alt="" className="mx-3 w-10 h-10 p-1 border" />  </div> : <div className='text-red-600 py-2 flex items-center text-xs'> <XCircleIcon className="w-6" /> No illustration visual</div>}
                </div>
            }
            )}

            {contactCycleLangData.map((data, index) => (
                <ContactCycleLang
                    key={index}
                    title={data.title}
                    cycleLangTypeList={data.cycleLangTypeList}
                    contacyCycleLangList={contacyCycleLangList}
                />
            ))}
        </div>
        <div className="p-2 bg-neutral-50">

            <div className="flex justify-end w-full">
                <button
                    className="w-fit cursor-pointer flex bg-blue-200 rounded-full px-3 py-1 text-xs   hover:bg-blue-500 hover:text-white text-blue-700  space-x-2 items-center"
                    onClick={(e) => showEditContactDialog({ event: e, contact: partenaire.contact })}>
                    <PencilSquareIcon className='w-4 ' />   edit</button>
            </div>

            <FormulaireHandler
                cycleLangEventLigneList={cycleLangEventLigneList}
                sendButtonRef={sendButtonRef}
                handleSendForm={handleSendForm}
                partenaire={partenaire} />
        </div>

        <div className="basis-2/12 flex flex-col items-center px-2  justify-center space-y-1 py-2 hidden ">
            <a className='flex ring-2  ring-pink-200 rounded-full text-xs px-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center'
                target="_blank"
                href={`/events/${currentEvent.id_event}/signup/startup-onboarding-fast/c/${partenaire.contact.id_contact}/?form-name=startup-onboarding-fast&formisvisible=1&id_formulaire=28&lng=en`}>
                <LinkIcon className='w-4 ' />Show form</a>


            {emailSent ? <div className="cursor-pointer flex bg-green-200 rounded-full px-2 text-xs  py-2   text-green-700  space-x-2 items-center"> email sent</div> : <a
                ref={sendButtonRef}
                idFormulaire={28}
                className="cursor-pointer flex bg-pink-200 rounded-full px-2 text-xs  py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center"
                onClick={handleSendForm}>
                <PaperAirplaneIcon className='w-4 ' />Send a request for updating data</a>}

            <a
                ref={sendButtonRef}
                idFormulaire={47}
                className="cursor-pointer flex bg-pink-200 rounded-full px-2 text-xs py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center"
                onClick={handleSendForm}>
                <PaperAirplaneIcon className='w-4 ' />Send form - next level 2 and webcast recording</a>

            <a

                className="cursor-pointer flex bg-pink-200 rounded-full px-2 text-xs py-2  hover:bg-pink-600 hover:text-white text-pink-700  space-x-2 items-center"
                onClick={() => sendEmailNotification({
                    dest: partenaire.contact.mail,
                    email: partenaire.contact.mail,
                    mobile: partenaire.contact.port,
                    objet: "Your solution have been accepted to be showcased at myGloblVillage pitching session",
                    body: `<p>Your solution have been accepted to be showcased at myGloblVillage pitching session.  </p>
<p>Please join the WhatsApp group chat in order to finalize the showcase of your startup or innovative solution and ask any question to the organizers</p>

<p><a href='https://chat.whatsapp.com/GXKsKHNtC8c5BZuSoMlEqs'>Join the WhatsApp group chat</a></p>


<img src="https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=https%3A%2F%2Fchat.whatsapp.com%2FGXKsKHNtC8c5BZuSoMlEqs">`
                })}>
                <PaperAirplaneIcon className='w-4 ' />WhatsApp Onboarding</a>
        </div>
    </div>
}

const JuryEventList = ({ presta, juryDemoList }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const sorted = sortByAttribute(juryDemoList, "heure_debut");

    return <div  >
        <p className='mt-5'><b>Sessions des pitch</b></p>

        <div className="overflow-hidden mb-10 mt-2 ring-1 ring-black ring-opacity-5 md:rounded-lg">

            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50 hidden">
                    <tr>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Session</th>
                    </tr>
                </thead>
                <tbody className="divide-y">
                    {sorted
                        .filter(item =>
                            //   (item.jury_event.conf_event.type && ["48", "63", "66", "67", "74", "88", "92", "96", "97"].includes(item.jury_event.conf_event.type.id_conf_event_type)) &&
                            item.jury_event.thematique != 'y'
                            && item.presta.id_presta == presta.id_presta
                            && item.jury_event.conf_event.publier != 'n')
                        .map(jury_demo => {

                            const confEvent = jury_demo.jury_event.conf_event

                            return <tr key={jury_demo.id_jury_demo}
                                className='  px-3 py-2 text-sm text-gray-500 w-3/4 divide-y'
                            >
                                <td>
                                    {(confEvent.conf_event_date_local)}, {confEvent.heure_debut_local} - {confEvent.heure_fin_local}</td>
                                <td className='  px-3 py-2 text-sm text-gray-500'>

                                    {jury_demo.is_nomine === '1' ? <span className="text-white badge rounded-pill bg-success me-2" >
                                        <i className="fas fa-check-circle"></i> Nominated Solution </span> : ''
                                    }

                                    <p>{parse(jury_demo.jury_event.conf_event.conf_event_lang.cel_titre.replace(/(<([^>]+)>)/gi, "") ?? "")}
                                    </p>
                                </td>
                                <td><Link className="text-nowrap text-blue-800 rounded-full bg-white px-2.5 py-1 text-xs hover:bg-gray-50" to={`/events/${currentEvent?.id_event}/programm/${confEvent.id_conf_event}/parcours-details`}>{t('global.en_savoir_plus')}</Link></td>
                            </tr>
                        })}
                </tbody>
            </table>
        </div>
    </div>
}

const Communaute = ({ communauteList }) => {

    return <div className="bg-neutral-100 p-1 flex  items-center space-x-1 rounded">
        <span> Pays affiliés</span>
        {communauteList && communauteList
            //dedup by communaute.cycle_lang.id_cycle_lang
            .filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.cycle_lang.id_cycle_lang === item.cycle_lang.id_cycle_lang
                ))
            )
            .map(communaute => <div>  <img src={communaute?.cycle_lang?.attachedFilesCollection.find(item => item.id_attached_file_type == 65).small} alt="" class="false  w-10 lg:w-10 rounded" />

                {/* {communaute.cycle_lang.nom_cycle_lang} */}
            </div>)}
    </div>

}

const Candidat = ({ candidat, index, juryDemoList, event }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const videoUrl = candidat.presta.video_url !== '' ? <a target="_blank" href={candidat.presta.video_url} style={{ color: "#339af0" }}><small><i class="fas fa-video"></i>{candidat.presta.video_url}</small></a> : '';

    const [communauteList, setCommunaute] = useState(false);

    const getCommunaute = async () => {

        await fetch(`${apiUrlTranslation}?action=getCommunaute&filter= AND c.id_contact="${candidat.presta.id_contact.id_contact}" 
        AND ecl.id_event=1789 `)

            .then(response => response.json())
            .then(data => {
                setCommunaute(data);
            })
    }

    useEffect(() => {
        getCommunaute();
    }, [i18n.resolvedLanguage])


    return <div className="row border-x border-y  mb-2 rounded-2xl bg-white">

        <div className="col-md-3">

            <div className=' d-flex align-items-center'>
                <p className='text-muted'>#{index}</p>

                <span className="text-white hidden">{candidat.presta.id_contact.id_contact}</span>

                <div class="card m-2" style={{ width: "100%" }}>

                    {candidat.presta.id_contact.logos ?
                        <img src={candidat.presta.id_contact.logos.small} class="card-img-top" alt="..." /> : <div className="bg-neutral-900">{candidat.presta.id_contact.societe} </div>
                    }

                    <div class="card-body ">
                        <h6 class="card-title"> {candidat.presta.id_contact.societe}   	</h6>
                        <p class="card-text"></p>

                        <i className="fas fa-map-marker-alt text-muted"></i>
                        <span
                            style={{ texTransform: "uppercase" }}
                            className="text-muted text-sm w-6"> {candidat.presta.id_contact.ville}, {candidat.presta.id_contact.pays}</span>
                        <img src={candidat.presta.id_contact.flag}
                            alt=""
                            class="m-2 w-6"
                        />
                    </div>
                </div>

                <div class="card m-2" style={{ width: "100%" }}>
                    <img src={candidat.presta.id_contact.photos?.small} class="card-img-top" alt="..." />
                    <div class="card-body">
                        <h6 class="card-title"> {candidat.presta.id_contact.prenom}  {candidat.presta.id_contact.nom}	</h6>
                        <p class="card-text"></p>
                    </div>
                </div>

            </div>
            <Communaute communauteList={communauteList} />

        </div>

        <div className="col pt-3">


            <div className="rounded visuel hidden">
                {candidat.presta.id_contact.logos && <img
                    style={{ width: "140px" }}
                    className="py-3"
                    src={candidat.presta.id_contact.logos.small} />} </div>

            <div className="flex items-center justify-between">
                <b className="py-3 text-xl text-pink-600">{candidat.presta.presta_nom}</b>
                <Link target="_blank" className="border-x border-y rounded-full px-3 border-pink-600 text-sm py-2" to={`/events/${event?.id_event}/programm/${candidat.presta.id_presta}/demo-details`}>{t('global.en_savoir_plus')}</Link>
            </div>

            <p>{
                //remove html tags
                parse(candidat.presta.presta_accroche.substring(0, 200)
                    .replace(/(<([^>]+)>)/gi, "") ?? " "

                )}...</p>
            {videoUrl}
            <JuryEventList
                juryDemoList={juryDemoList}
                juryDemo={candidat}
                presta={candidat.presta} />
        </div>

    </div>
}

const CountryFilter = ({ countryList, selectedCountry, setSelectedCountry }) => {

    const eventContext = useContext(EventContext);

    const { currentEvent } = eventContext;

    const [clipboard, setClipboard] = useState(null);

    const handleChange = (e) => {
        setSelectedCountry(e.target.value);
    }

    const handleClipboard = ({ url }) => {
        navigator.clipboard.writeText(url);
        setClipboard(true);

        setTimeout(() => {
            setClipboard(false);
        }, 2000);
    }

    useEffect(() => {

    }, [currentEvent])

    if (!currentEvent) return null;

    const url = `/events/${currentEvent.id_event}/candidats-all/${selectedCountry}`;

    return <div className='px-5 bg-neutral-900 mx-5 my-3 rounded-2xl text-white'>

        {!selectedCountry && <select className="bg-white-300 p-2 my-2 rounded text-black"
            onChange={handleChange}>
            <option>Sort by country</option>
            <option value="">All</option>
            {countryList && countryList
                //order by country
                .sort((a, b) => a.localeCompare(b))
                .map(country => <option value={country}>{country}</option>)}
        </select>}

        {selectedCountry && <div className=" flex">

            <div className='flex space-x-4 items-center py-3'>

                <a className='flex  cursor-pointer bg-pink-600 text-white p-2 rounded-full px-4 '
                    href={url}>

                    <LinkIcon className="w-4 mr-2" /> Go to {selectedCountry} Url </a>
                <a
                    className=" flex cursor-pointer bg-pink-600 text-white p-2  rounded-full px-4"
                    onClick={() => {
                        handleClipboard({ url });
                    }}
                >
                    {!clipboard ? <ClipboardIcon className="w-4 mr-2" /> :
                        <ClipboardDocumentCheckIcon className="w-4 mr-2" />}

                    Copy {selectedCountry} URL to clipboard
                </a>
            </div>
        </div>}

    </div>
}

const CandidatsAll = () => {

    const { country } = useParams();

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation()

    const [candidats, setCandidats] = useState(null);

    const [juryDemoList, setJuryDemoList] = useState(null);

    const eventContext = useContext(EventContext);

    const [prestaList, setPrestaList] = useState(null);

    const [contactConferencierList, setContactConferencierList] = useState(false);

    const [countryList, setCountryList] = useState(null);

    const [selectedCountry, setSelectedCountry] = useState(null);

    const { currentEvent } = eventContext;

    const [contactDialogIsOpen, setContactDialogIsOpen] = useState(false);

    const [activeContact, setActiveContact] = useState(null);

    const [cycleLangEventLigneList, setCycleLangEventLigneList] = useState(null);

    const getCycleLangEventLigne = async () => {


        const params = ` WHERE id_event_ligne=(SELECT id_event_ligne FROM events WHERE id_event="${currentEvent.id_event}") `;

        await fetch(`${apiUrlTranslation}?action=getCycleLangEventLigne&params=${params}`)
            .then(res => res.json())
            .then(res => setCycleLangEventLigneList(res))
    }


    const showEditContactDialog = ({ event, contact }) => {

        setActiveContact(contact);

        console.log(contact);

        setContactDialogIsOpen(true);
    }

    const getPrestaList = async ({ idContactList }) => {

        await fetch(`${apiUrlTranslation}?action=getPrestaList&params= WHERE id_contact IN (${idContactList.join(',')} )`)
            .then(res => res.json())
            .then(res => setPrestaList(res)) //setDemo(res[0]))      
    }

    useEffect(() => {



        const getContactConferencierList = async () => {

            await fetch(`${apiUrlTranslation}?action=getContactConferencierList&filter= AND id_event=${currentEvent.id_event}  AND cf.statut IN('offreur_de_solution', 'candidat-pitch') GROUP BY cf.id_contact`)
                .then(res => res.json())
                .then(res => {
                    setContactConferencierList(res);

                    setCountryList(res.map(item => item.contact.pays).filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t === item
                        ))
                    ))
                    return res;
                })
                .then(res =>
                    getPrestaList({
                        idContactList: res.
                            //dedup by   id_contact
                            filter((item, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id_contact === item.id_contact
                                ))
                            )
                            .map(item => item.id_contact)
                    })
                )
        }

        const getCandidats = async () => {

            await fetch(`${apiUrlTranslation}?action=getJuryDemoList&params=WHERE id_event=${currentEvent.id_event}`)
                .then(res => res.json())
                .then(all => {

                    const allFiltered = all
                        //.filter(item => item.is_visible == '1')
                        .map(item => {

                            return { ...item, id_presta: item.presta.id_presta }
                        })

                    setJuryDemoList(all)
                    setCandidats(removeDuplicateObjectFromArray(allFiltered, "id_presta"))
                })
        }
        if (currentEvent) {
            getCandidats();
            getContactConferencierList();
            getCycleLangEventLigne();
        }

        if (country && typeof country === 'string' && typeof setSelectedCountry === 'function') {
            setSelectedCountry(country.toUpperCase());
        }

    }, [currentEvent, i18n.resolvedLanguage, country]);

    return <Layout title={<div className="text-white">VOTRE BOITE A OUTIL DE COORDINATEUR </div>} className="">

        <EditContactDialog
            setContactConferencierList={setContactConferencierList}
            contactConferencierList={contactConferencierList}
            setContactDialogIsOpen={setContactDialogIsOpen}
            contactDialogIsOpen={contactDialogIsOpen}
            setActiveContact={setActiveContact}
            contact={activeContact} />

        <CountryFilter
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            countryList={countryList} />

        {/* {t('event_nav.annuaire_candidats')} */}
        {country && <Onboarding
            idFormulaireList={[28, 67, 68, 69]}
            event={currentEvent}
        />}

        {candidats && candidats.length === "0" && <div className='container alert alert-success'>A découvrir prochainement</div>}

        <div className="text-white mx-5 py-3 text-2xl font-bold">Phase 3 : suivi de la participation de votre écosystème à l'événement
        </div>

        {!contactConferencierList && <div className="text-pink-400 mx-auto max-w-2xl text-center py-5 animate-pulse">The process may take some time. Please stay connected.<Loader /></div>}

        <div className="px-5 mx-auto">

            <div className="divide-y space-y-2">
                {contactConferencierList && contactConferencierList
                    //dedup by id_contact

                    .filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t.id_contact === item.id_contact
                        ))
                    )
                    //filter by country
                    .filter(item => selectedCountry ? item.contact.pays == selectedCountry : item)
                    //order by partenaire.contact.societe
                    .sort((a, b) => a.contact.societe.localeCompare(b.contact.societe))
                    .map((partenaire, index) =>
                        <CandidatHorsJuryDemo
                            cycleLangEventLigneList={cycleLangEventLigneList}
                            setContactDialogIsOpen={setContactDialogIsOpen}
                            showEditContactDialog={showEditContactDialog}
                            prestaList={prestaList && prestaList
                                .filter(presta => presta.id_contact == partenaire.id_contact)
                            }
                            index={index}
                            contactConferencierList={contactConferencierList}
                            partenaire={partenaire} />)}
            </div>

            {/* 
            {candidats ? candidats.map((item, index) => <Candidat
                event={currentEvent}
                index={index + 1}
                juryDemoList={juryDemoList}
                key={index}
                candidat={item} />) : <Loader />} */}

        </div>
    </Layout >
};

export default CandidatsAll;