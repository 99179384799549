import React from 'react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import EventCycleLangPrestaTypeList from './EventCycleLangPrestaTypeList'
import { useParams } from "react-router-dom";

import { createPortal } from 'react-dom';
import Checkout from './Checkout';

const EventCycleLangPacks = ({ eventCycleLang, cycleLang, contact, checkContactisTrue, handleLoginPaiement }) => {

    const { idEvent } = useParams();

    return (

        <div>
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg  lg:flex lg:max-w-none" >
                <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                    <p className="lg:text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                        <span style={{ color: `#${cycleLang.cycle_lang_color_1}` }}>{eventCycleLang?.event_cycle_lang_nom}</span></p>
                    <p className="mt-6 text-base text-gray-500 d-none">
                        Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis
                        repellendus etur quidem assumenda.
                    </p>
                    <div className="mt-8">
                        <div className="flex items-center">
                            <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600 d-none">
                                Ce qui est inclu dans votre pack
                            </h4>
                            <div className="flex-1 border-t-2 border-gray-200" />
                        </div>

                        <EventCycleLangPrestaTypeList eventCycleLang={eventCycleLang} />
                    </div>
                </div>
                <div className=" d-none bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                    <p className="text-lg font-medium leading-6 text-gray-900 d-none">Pay once, own it forever</p>
                    <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-indigo-600" style={{ color: `#${cycleLang.cycle_lang_color_1}` }}>
                        <span className='d-none'>1490.00€</span>
                    </div>

                    <p className="mt-4 text-sm d-none">
                        <a href="#" className="font-medium text-gray-500 underline">
                            Learn about our membership policy
                        </a>
                    </p>
                    <div className="mt-6">
                        <div className="">

                            <a
                                onClick={() => handleLoginPaiement({
                                    id_contact: contact.id_contact,
                                    mail: contact.mail,
                                    pass_encrypt: contact.pass_encrypt
                                })}
                                href_={`https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=${idEvent}&iecl=${eventCycleLang?.id_event_cycle_lang}`}
                                className="flex items-center justify-center rounded-full border border-transparent bg-pink-600 px-5 py-3 text-lg font-medium text-white hover:bg-pink-500
                                  
                                    "
                            >
                                Je réserve mon pack beta
                            </a>
                        </div>
                    </div>
                    <div className="mt-4 text-sm">
                        <a href="#" className="font-medium text-gray-900 d-none">
                            Get a free sample <span className="font-normal text-gray-500">(20MB)</span>
                        </a>
                    </div>
                </div>

            </div>
            <div className='max-w-2xl mx-auto py-10'>
                {/* {createPortal(<Checkout />, document.body)} */}

            </div>
        </div>





    )

    return (
        <div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-14">
            <div className="relative">
                <div className="absolute inset-0 h-1/2 bg-gray-100" />
                <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
                        <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                            <p className="lg:text-5xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight"> <span style={{ color: `#${cycleLang.cycle_lang_color_1}` }}>{eventCycleLang?.event_cycle_lang_nom}</span></p>
                            <p className="mt-6 text-base text-gray-500 d-none">
                                Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis
                                repellendus etur quidem assumenda.
                            </p>
                            <div className="mt-8">
                                <div className="flex items-center">
                                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600 d-none">
                                        Ce qui est inclu dans votre pack
                                    </h4>
                                    <div className="flex-1 border-t-2 border-gray-200" />
                                </div>
                                {/* <ul role="list" className="d-none mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                                    {includedFeatures.map((feature) => (
                                        <li key={feature} className="flex items-start lg:col-span-1">
                                            <div className="flex-shrink-0">
                                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                            </div>
                                            <p className="ml-3 text-sm text-gray-700">{feature}</p>
                                        </li>
                                    ))}
                                </ul> */}

                                <EventCycleLangPrestaTypeList eventCycleLang={eventCycleLang} />
                            </div>
                        </div>
                        <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                            <p className="text-lg font-medium leading-6 text-gray-900 d-none">Pay once, own it forever</p>
                            <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-indigo-600" style={{ color: `#${cycleLang.cycle_lang_color_1}` }}>
                                <span className='d-none'>1490.00€</span>
                            </div>

                            <p className="mt-4 text-sm d-none">
                                <a href="#" className="font-medium text-gray-500 underline">
                                    Learn about our membership policy
                                </a>
                            </p>
                            <div className="mt-6">
                                <div className="">

                                    <a
                                        onClick={() => handleLoginPaiement({
                                            id_contact: contact.id_contact,
                                            mail: contact.mail,
                                            pass_encrypt: contact.pass_encrypt
                                        })}
                                        href_={`https://www.mlg-consulting.com/myglobalvillage/sign.php?ie=${idEvent}&iecl=${eventCycleLang?.id_event_cycle_lang}`}
                                        className="flex items-center justify-center rounded-full border border-transparent bg-pink-600 px-5 py-3 text-lg font-medium text-white hover:bg-pink-500
                                  
                                    "
                                    >
                                        Je réserve mon pack beta
                                    </a>
                                </div>
                            </div>
                            <div className="mt-4 text-sm">
                                <a href="#" className="font-medium text-gray-900 d-none">
                                    Get a free sample <span className="font-normal text-gray-500">(20MB)</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default EventCycleLangPacks