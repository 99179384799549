import { useEffect, useState } from "react"
import { API_URL } from "../config"
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import HTMLReactParser from 'html-react-parser';
import { useFormContext } from "react-hook-form";
import { withWidth } from "@material-ui/core";


const EventCycleLangPrestaTypeList = ({ eventCycleLang }) => {

    const [eventCycleLangPrestaTypeList, setEventCycleLangPrestaTypeList] = useState(null);

    const [panier, setPanier] = useState([]);

    const [panierTotalHT, setPanierTotalHT] = useState(0);

    const [panierTotalTTC, setPanierTotalTTC] = useState(0);

    const { register, formState, setValue } = useFormContext();

    const handleChange = (e) => {

        const { name, value: stringValue } = e.target;
        const value = Number(stringValue);

        let newPanier = { ...panier, [name]: value };

        // If the number is 0, remove the element from the cart
        if (value === 0) {
            delete newPanier[name];
        }

        setPanier(newPanier);

        setValue('panier', newPanier);

        let totalHT = 0;

        let totalTTC = 0;

        for (const [id_presta_type, quantity] of Object.entries(newPanier)) {

            const eventCycleLangPrestaType = eventCycleLangPrestaTypeList.find(eventCycleLangPrestaType => eventCycleLangPrestaType.presta_type.id_presta_type === (id_presta_type));

            totalHT += eventCycleLangPrestaType.presta_type.prix_unitaire_ht * quantity;

            totalTTC += (eventCycleLangPrestaType.presta_type.prix_unitaire_ht * quantity) * (1 + eventCycleLangPrestaType.presta_type.presta_type_tva / 100);
        }

        setPanierTotalTTC(totalTTC);

        setPanierTotalHT(totalHT);
    }

    useEffect(() => {

        const getEventCycleLangPrestaType = async () => {

            await fetch(`${API_URL}?action=getEventCycleLangPrestaType&params=WHERE id_event_cycle_lang=${eventCycleLang.id_event_cycle_lang}`)
                .then(res => res.json())
                .then(res => {
                    setEventCycleLangPrestaTypeList(res)
                })
        }

        eventCycleLang && getEventCycleLangPrestaType();

    }
        , [eventCycleLang])

    if (!eventCycleLangPrestaTypeList) return null

    return (
        <div>

            <div className="bg-red-50  text-red-700 p-4">
                Si vous disposez d'un code de réduction, inscrivez vous avec la formule de votre choix. Il vous sera demandé et appliqué en fin d'inscription.
            </div>

            <table className=' mt-2'>

                <thead className="border-b border-gray-300 ">
                    <tr className="divide-x ">
                        <th className="py-3.5 ">Libellé</th>
                        <th className="px-3 text-right">PU € HT</th>
                        <th className="px-3 text-right">Quantité</th>
                        <th className="px-3 text-right">%TVA</th>
                        <th className="px-3 text-right">Total HT</th>
                        <th className="px-3 text-right"> Total TTC</th>
                    </tr>
                </thead>

                <tbody>

                    {eventCycleLangPrestaTypeList.map(eventCycleLangPrestaTypeList =>
                        <tr className="divide-x  border-b " key={eventCycleLangPrestaTypeList.id_event_cycle_lang_presta_type}>

                            <td
                                key={eventCycleLangPrestaTypeList.id_event_cycle_lang_presta_type}
                                className="py-3 w-7/12">

                                <div className="flex items-center space-x-5">
                                    <div className="basis-1/12">
                                        {eventCycleLangPrestaTypeList.presta_type.visuel != 'https://www.mlg-consulting.com/manager_cc/docs/archives/' && <div>  <img src={eventCycleLangPrestaTypeList.presta_type.visuel} /></div>}
                                    </div>
                                    <div className="basis-11/12">
                                        <p className="flex font-medium leading-6 text-gray-900">{eventCycleLangPrestaTypeList.presta_type.presta_type_nom}</p>
                                        <div className='hidden mt-2 text-l py-2 font-bold'>{eventCycleLangPrestaTypeList.presta_type.prix_unitaire_ht}€</div>
                                        <div className='  text-gray-500 text-xs'>{HTMLReactParser(eventCycleLangPrestaTypeList.presta_type.descriptif)}</div>
                                    </div>
                                </div>
                            </td>
                            <td className="px-3 text-right">{eventCycleLangPrestaTypeList.presta_type.prix_unitaire_ht}</td>
                            <td className="px-3 text-center">
                                <input className="w-14 h-10 border px-2" min="0" defaultValue={0} type="number" name={eventCycleLangPrestaTypeList.presta_type.id_presta_type} onChange={handleChange} />
                            </td>
                            <td className="px-3 text-right">{eventCycleLangPrestaTypeList.presta_type.presta_type_tva}%</td>
                            <td className="px-3 text-right"> {isNaN(panier[eventCycleLangPrestaTypeList.presta_type.id_presta_type] * eventCycleLangPrestaTypeList.presta_type.prix_unitaire_ht) ? 0 : (panier[eventCycleLangPrestaTypeList.presta_type.id_presta_type] * eventCycleLangPrestaTypeList.presta_type.prix_unitaire_ht).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}</td>
                            <td className="px-3 text-right">
                                {
                                    // total ttc
                                    isNaN(panier[eventCycleLangPrestaTypeList.presta_type.id_presta_type] * eventCycleLangPrestaTypeList.presta_type.prix_unitaire_ht * (1 + eventCycleLangPrestaTypeList.presta_type.presta_type_tva / 100)) ? 0 : (panier[eventCycleLangPrestaTypeList.presta_type.id_presta_type] * eventCycleLangPrestaTypeList.presta_type.prix_unitaire_ht * (1 + eventCycleLangPrestaTypeList.presta_type.presta_type_tva / 100)).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
                                }
                            </td>

                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr className="text-right text-lg font-bold divide-x">
                        <td colSpan={4} className="px-3 "></td>
                        <td className="py-2 px-3 bg-blue-50">
                            {panierTotalHT.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}

                        </td>
                        <td className="py-2 px-3 bg-blue-50">
                            {panierTotalTTC.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}</td>
                    </tr>
                </tfoot>

            </table>
        </div>
    )
}

export default EventCycleLangPrestaTypeList