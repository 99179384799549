import React, { useContext } from 'react'
import Nav from './Nav'
import NavEvent from './NavEvent'
import {
    useParams

} from "react-router-dom";

import styled from 'styled-components';
import NavTranslation from './NavTranslation';
import "./Layout.css"
import LayoutFooter from './LayoutFooter';
import darkModeContext from '../contexts/DarkModeContext';
import PageSection from './PageSection';
import Navbar from './Navbar';

const H1 = styled.h1`
 
    font-size: 2.5rem !important;
    text-transform: lowercase;
    font-weight: 800 !important;
    &:first-letter  {
        text-transform: uppercase
      }
`

const Layout = (props) => {

    let { idEvent, id_cycle_lang } = useParams();

    const { darkMode, toggleDarkMode } = useContext(darkModeContext);


    return (

        <div className={`flex flex-col ${darkMode ? 'dark' : ''} relative h-full  min-h-screen justify-content-between`} >

            {/* <Navbar /> */}
            {
                idEvent !== '' && <header className=''>
                    {idEvent ? <NavEvent /> : <Nav />}
                </header>
            }

            {
                //  ["2270", "3013", "3014"].includes(id_cycle_lang) && <NavTranslation />
            }

            <div className="h-10 dark:bg-neutral-950 d-none"></div>
            <main className={`flex-grow ${props.className} ${!/(^|\s)pt-[0-4]/.test(props.className) && ''}  ${props.darkmode ? ` dark:text-white dark:bg-neutral-950` : ''}`}>
                {props.title && <H1 className="text-center pb-5 mt-5  dark:text-neutral-400">{props.title}</H1>}
                {props.children}
            </main>


            {

                idEvent == 1822 && <PageSection title="Nous contacter">

                    <div className="text-white flex  flex-col lg:flex-row mx-auto max-w-xl justify-center space-x-10 space-y-10  lg:space-y-0 items-center text-2xl">
                        <div >Fabrice Galloo <br />
                            fabrice.galloo@evalley.fr<br />
                            +33(0)6 63 66 59 11
                        </div>
                        <div>
                            Baptiste Boulanger<br />
                            baptiste.boulanger.pro@gmail.com<br />
                            +33(0)6 81 88 35 49
                        </div>
                    </div>

                </PageSection>
            }


            <LayoutFooter />
        </div>


    )
}

export default Layout
